import React from 'react';

const Contact: React.FC = () => {
  return (
    <div className="max-w-2xl mx-auto">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">联系我</h1>
      <div className="bg-white shadow rounded-lg p-6">
        <div className="space-y-4">
          <div className="flex items-center">
            <svg className="h-6 w-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg>
            <span className="ml-3 text-lg">Telegram: @aaawantongshuju</span>
          </div>
          <p className="text-gray-600 mt-4">
            我们提供优质的博彩数据，欢迎通过 Telegram 联系我。我会尽快回复您的咨询。
          </p>
          <a
            href="https://t.me/aaawantongshuju"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-blue-500 text-white px-6 py-3 rounded-md font-medium hover:bg-blue-600 transition-colors mt-4"
          >
            打开 Telegram 联系我
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import DataDisplay from '../components/DataDisplay';

const Home: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">数据展示(所有数据均每4小时更新一次,请及时查看)</h1>
        {!isAuthenticated && (
          <Link
            to="/login"
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            登录查看更多数据 →
          </Link>
        )}
      </div>
      <DataDisplay />
      {!isAuthenticated && (
        <div className="mt-4 bg-blue-50 p-4 rounded-md">
          <p className="text-blue-700">
            当前显示 50 条数据。登录后可查看完整 100 条数据。
          </p>
        </div>
      )}
    </div>
  );
};

export default Home;
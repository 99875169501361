import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';

interface QnData {
  username: string;
  nickname: string;
  wallet_addr: string;
  realname: string;
  idcard: string;
  mobile: string;
  reg_ip: string;
  create_time: number;
  cards: string[];
}

interface MemberData {
  username: string;
  realname: string;
  tel: string;
  reg_ip: string;
  qq: string;
  email: string;
  create_time: number;
}

const DataDisplay: React.FC = () => {
  const { isAuthenticated, token } = useAuth();
  const [qnData, setQnData] = useState<QnData[]>([]);
  const [memberData, setMemberData] = useState<MemberData[]>([]);
  const [activeTab, setActiveTab] = useState<'qn' | 'member'>('qn');

  const fetchData = async () => {
    try {
      const headers: HeadersInit = {};
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
      }

      const qnResponse = await fetch('https://api.wantongshuju.com/api/v1/data/qn', {
        headers
      });
      const qnData = await qnResponse.json();
      setQnData(qnData);

      const memberResponse = await fetch('https://api.wantongshuju.com/api/v1/data/member_aggregate', {
        headers
      });
      const memberData = await memberResponse.json();
      setMemberData(memberData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  const formatTime = (timestamp: number) => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  return (
    <div className="container mx-auto p-4">
      <div className="mb-4 space-y-4">
        <div className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded ${activeTab === 'qn' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('qn')}
          >
            钱能钱包数据
          </button>
          <button
            className={`px-4 py-2 rounded ${activeTab === 'member' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setActiveTab('member')}
          >
            综合数据
          </button>
        </div>

        <div className="flex justify-between items-center bg-blue-50 p-4 rounded-lg shadow">
          <div className="text-sm text-gray-700">
            当前显示 {isAuthenticated ? '100' : '50'} 条记录
            {!isAuthenticated && <span className="ml-1">（登录后可查看更多数据）</span>}
          </div>
          <div className="flex items-center text-blue-600">
            <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span>左右滑动查看更多数据如银行卡号</span>
          </div>
        </div>
      </div>

      <div className="border rounded-lg shadow overflow-x-auto bg-white">
        {activeTab === 'qn' ? (
          <table className="min-w-max w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left min-w-[120px]">用户名</th>
                <th className="py-3 px-6 text-left min-w-[120px]">昵称</th>
                <th className="py-3 px-6 text-left min-w-[200px]">钱能钱包地址</th>
                <th className="py-3 px-6 text-left min-w-[100px]">真实姓名</th>
                <th className="py-3 px-6 text-left min-w-[180px]">身份证</th>
                <th className="py-3 px-6 text-left min-w-[120px]">手机号</th>
                <th className="py-3 px-6 text-left min-w-[120px]">注册IP</th>
                <th className="py-3 px-6 text-left min-w-[160px]">创建时间</th>
                <th className="py-3 px-6 text-left min-w-[200px]">银行卡号/支付宝/微信</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm">
              {qnData.map((item, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="py-3 px-6">{item.username}</td>
                  <td className="py-3 px-6">{item.nickname}</td>
                  <td className="py-3 px-6 font-mono">{item.wallet_addr}</td>
                  <td className="py-3 px-6">{item.realname}</td>
                  <td className="py-3 px-6 font-mono">{item.idcard}</td>
                  <td className="py-3 px-6">{item.mobile}</td>
                  <td className="py-3 px-6">{item.reg_ip}</td>
                  <td className="py-3 px-6">{formatTime(item.create_time)}</td>
                  <td className="py-3 px-6">
  {item.cards.map((card, cardIndex) => (
    <div key={cardIndex}>{card}</div>
  ))}
</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <table className="min-w-max w-full table-auto">
            <thead>
              <tr className="bg-gray-100 text-gray-600 uppercase text-sm leading-normal">
                <th className="py-3 px-6 text-left min-w-[120px]">Username</th>
                <th className="py-3 px-6 text-left min-w-[100px]">Real Name</th>
                <th className="py-3 px-6 text-left min-w-[120px]">Tel</th>
                <th className="py-3 px-6 text-left min-w-[120px]">Reg IP</th>
                <th className="py-3 px-6 text-left min-w-[120px]">QQ</th>
                <th className="py-3 px-6 text-left min-w-[180px]">Email</th>
                <th className="py-3 px-6 text-left min-w-[160px]">Create Time</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm">
              {memberData.map((item, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                  <td className="py-3 px-6">{item.username}</td>
                  <td className="py-3 px-6">{item.realname}</td>
                  <td className="py-3 px-6">{item.tel}</td>
                  <td className="py-3 px-6">{item.reg_ip}</td>
                  <td className="py-3 px-6">{item.qq}</td>
                  <td className="py-3 px-6">{item.email}</td>
                  <td className="py-3 px-6">{formatTime(item.create_time)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DataDisplay;
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/Layout';
import Home from './pages/Home';
import Login from './components/Login';
import Register from './components/Register';
import Contact from './pages/Contact';
import { useEffect } from 'react';

const App: React.FC = () => {
  useEffect(() => {
    document.title = "万通数据-优质的博彩数据提供商";
  }, []);
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
};

export default App;
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, logout } = useAuth();
  const location = useLocation();

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <Link to="/" className="flex items-center px-2 py-2 text-gray-700 hover:text-gray-900">
                <span className="text-xl font-bold">Data Dashboard</span>
              </Link>
            </div>
            <div className="flex items-center space-x-4">
              <Link 
                to="/contact" 
                className={`px-3 py-2 rounded-md text-sm font-medium ${
                  location.pathname === '/contact' 
                    ? 'text-blue-700' 
                    : 'text-gray-700 hover:text-gray-900'
                }`}
              >
                联系我
              </Link>
              {!isAuthenticated ? (
                <div className="flex space-x-2">
                  <Link 
                    to="/login" 
                    className="bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600"
                  >
                    登录
                  </Link>
                  <Link 
                    to="/register" 
                    className="bg-gray-100 text-gray-700 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-200"
                  >
                    注册
                  </Link>
                </div>
              ) : (
                <button
                  onClick={logout}
                  className="bg-red-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-red-600"
                >
                  退出登录
                </button>
              )}
            </div>
          </div>
        </div>
      </nav>
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {children}
      </main>
    </div>
  );
};

export default Layout;